<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";
  import { TextField } from "$lib/components/atoms/Field";
  import { Button } from '$lib/components/atoms/Button';
  import { AuthFieldsWrapper } from '$lib/components/molecules/Wrapper';
	import { useOktaStore } from "$lib/utils/auth-store";

  export let embedInCard = false;

  let isSendingEmail: boolean = false;
  let emailSent: boolean = false;

  const authStore = useOktaStore();
  const dispatch = createEventDispatcher();

  export let email: string | null = null;
  export let unlock: boolean = false;

  const onSubmit = (e: Event) => {
    e.preventDefault();

    isSendingEmail = true;

    if (!email) {
      isSendingEmail = false;
      return;
    }

    if (unlock) {
      // call unlock
      authStore
        .unlock(email)
        .then(() => {
          emailSent = true;
          dispatch("email-sent");
        })
        .catch((error) => {})
        .finally(() => {
          isSendingEmail = false;
        });
    } else {
      // call forgot password
      authStore
        .forgotPassword(email)
        .then(() => {
          emailSent = true;
          dispatch("email-sent");
        })
        .catch((error) => {})
        .finally(() => {
          isSendingEmail = false;
        });
    }
  };
</script>

<form class="password-recovery-form" on:submit={onSubmit}>
  {#if emailSent}
    <p class="password-recovery-text" in:fade>
      {#if unlock}
        Controlla la casella di posta, ti abbiamo inviato un link per sbloccare
        il tuo utente.
      {:else}
        Controlla la casella di posta, ti abbiamo inviato un link per
        reimpostare la tua password di accesso
      {/if}
    </p>
  {:else}
    <div in:fade out:fade>
      <p class="password-recovery-text">
        {#if unlock}
          Inserisci l'email con cui sei registrato, ti invieremo un link tramite
          email per sbloccare il tuo account.
        {:else}
          Inserisci l’email con cui sei registrato, ti invieremo un link tramite
          email per impostare una nuova password di accesso.
        {/if}
      </p>

      <AuthFieldsWrapper useWrapper={embedInCard}>
        <TextField
          class="col-span-full"
          name="email"
          id="email"
          type="email"
          label="Email"
          disabled={isSendingEmail}
          bind:value={email}
        />

        <Button
          class="col-span-full {!embedInCard && 'mt-4'}"
          type="submit"
          isLoading={isSendingEmail}
          disabled={isSendingEmail}
          fullWidth
        >
          Invia email
        </Button>
      </AuthFieldsWrapper>
    </div>
  {/if}
</form>

<style type="text/css" lang="scss" scoped>
  .password-recovery-form {
    @apply py-4;
  }

  .password-recovery-text {
    @apply mb-4;
  }
</style>
